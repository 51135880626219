@import '../../../../../styles/abstracts/variables';

.customRadioGroup {
  :global(.ant-radio-button-wrapper:first-child) {
    border-radius: 5px 0px 0px 5px;
  }
  :global(.ant-radio-button-wrapper:last-child) {
    border-radius: 0px 5px 5px 0px;
  }
}

.customSelect {
  :global(
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector
    ) {
    border-radius: 6px;
  }
}

.errorMessage {
  margin-top: 28px;
  padding: 9px 16px;
  width: 100%;
  background: #fff1f0;
  border: 1px solid #ffccc7;
  border-radius: 2px;
}

.providerCard {
  display: flex;
  padding: 8px 16px;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid var(--color-gray-5);
  background: #fff;
  width: 270px;
}

.selectArrow {
  :global(.ant-select-arrow) {
    line-height: 0 !important;
  }
}
