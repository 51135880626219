.collapse {
  background: #fff !important;
  border: none;

  :global(.ant-collapse-content) {
    border: none;
  }

  :global(.ant-collapse-item) {
    border: none !important;
  }

  :global(.ant-collapse-content-box) {
    border: none;
  }
}

.border {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.noPaddingHeader {
  :global(.ant-collapse-header) {
    padding: 0px !important;
  }

  :global(.ant-collapse-content-box) {
    padding: 0px !important;
  }
}
