@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import '~highcharts/css/highcharts';
@import '../styles/abstracts/variables';

body {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-layout.ant-layout-has-sider {
  min-height: 100vh;
}

.ant-popover-title {
  padding: 0;
}

.ant-popover-arrow {
  display: none;
}

.app-font-family {
  font-family: $font-family;
}

.tableParentHeader {
  text-align: center !important;
  height: 40px;
  padding: 0.25rem !important;
}

.top-4 {
  top: 1rem;
}
.-right-4 {
  right: -1rem;
}

.ant-table-cell-fix-left {
  z-index: 20 !important;
}

#hatch-left path {
  stroke: var(--color-geekblue-4);
  stroke-width: 2px;
}

.highcharts-tooltip-box {
  fill: white;
  fill-opacity: 1;
}

.highcharts-color-0,
.highcharts-color-1 {
  &.highcharts-series {
    fill: unset;
    stroke: unset;
  }
  &.highcharts-point {
    stroke: none;
  }
  &.highcharts-tooltip {
    stroke: none;
  }
}

.charts-color-class-0 {
  fill: var(--color-chart-1);
}
.charts-color-class-1 {
  fill: var(--color-chart-2);
}
.charts-color-class-2 {
  fill: var(--color-chart-3);
}
.charts-color-class-3 {
  fill: var(--color-chart-4);
}
.charts-color-class-4 {
  fill: var(--color-chart-5);
}
.charts-color-class-5 {
  fill: var(--color-chart-6);
}
.charts-color-class-6 {
  fill: var(--color-chart-7);
}
.charts-color-class-7 {
  fill: var(--color-chart-8);
}
.charts-color-class-8 {
  fill: var(--color-chart-9);
}
.charts-color-class-9 {
  fill: var(--color-chart-10);
}

.bar-chart-label {
  font-family: $font-family;
  font-size: 12px;
  color: var(--color-gray-8);
  font-weight: bold;
  // fill: var(--color-gray-8);
}

.text-with-no-margin {
  margin: 0 !important;
}

.text-with-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
.ant-notification {
  // generally all modal have z-index of approx 10000
  // so setting notification z-index to very high
  z-index: 99999 !important;
}

.fa-custom-ribbon
{
  font-size: 10px !important;
  font-weight: 400 !important;
  height: 14px !important;
  line-height: 14px !important;
  padding: 0 4px !important;
  top: -10px !important;
  right: -12px !important;
}