@import '../../styles/generated-tokens/css/variables';

.btn {
  border: 1px solid #dedede;
}

.btnV2 {
  border-radius: 4px;
  border: 1px solid #d9d9d9 !important;
  background: #fff;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.05) !important;
  padding: 0px !important;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnV2:active,
.btnV2:focus {
  background: #fff !important;
}

.codeBlockV2 {
  border-radius: 8px;
  background: var(--color-gray-4);
  padding: 18px 22px;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  user-select: none;
  word-break: break-word;
}

.codeBlockV2 * {
  user-select: none;
  white-space: initial;
  word-break: break-word;
}
