/**
 * Do not edit directly
 * Generated on Mon, 03 Mar 2025 10:59:31 GMT
 */

:root {
  --color-brand-1: #e6f7ff;
  --color-brand-2: #bae7ff;
  --color-brand-3: #91d5ff;
  --color-brand-4: #69c0ff;
  --color-brand-5: #40a9ff;
  --color-brand-6: #1890ff;
  --color-brand-7: #096dd9;
  --color-brand-8: #0050b3;
  --color-brand-9: #003a8c;
  --color-brand-10: #002766;
  --color-purple-1: #f9f0ff;
  --color-purple-2: #efdbff;
  --color-purple-3: #d3adf7;
  --color-purple-4: #b37feb;
  --color-purple-5: #9254de;
  --color-purple-6: #722ed1;
  --color-purple-7: #531dab;
  --color-purple-8: #391085;
  --color-purple-9: #22075e;
  --color-purple-10: #120338;
  --color-magenta-1: #fff0f6;
  --color-magenta-2: #ffd6e7;
  --color-magenta-3: #ffadd2;
  --color-magenta-4: #ff85c0;
  --color-magenta-5: #f759ab;
  --color-magenta-6: #eb2f96;
  --color-magenta-7: #c41d7f;
  --color-magenta-8: #9e1068;
  --color-magenta-9: #780650;
  --color-magenta-10: #520339;
  --color-geekblue-1: #f0f5ff;
  --color-geekblue-2: #d6e4ff;
  --color-geekblue-3: #adc6ff;
  --color-geekblue-4: #85a5ff;
  --color-geekblue-5: #597ef7;
  --color-geekblue-6: #2f54eb;
  --color-geekblue-7: #1d39c4;
  --color-geekblue-8: #10239e;
  --color-geekblue-9: #061178;
  --color-geekblue-10: #030852;
  --color-blue-1: #e6f7ff;
  --color-blue-2: #bae7ff;
  --color-blue-3: #91d5ff;
  --color-blue-4: #69c0ff;
  --color-blue-5: #40a9ff;
  --color-blue-6: #1890ff;
  --color-blue-7: #096dd9;
  --color-blue-8: #0050b3;
  --color-blue-9: #003a8c;
  --color-blue-10: #002766;
  --color-cyan-1: #e6fffb;
  --color-cyan-2: #b5f5ec;
  --color-cyan-3: #87e8de;
  --color-cyan-4: #5cdbd3;
  --color-cyan-5: #36cfc9;
  --color-cyan-6: #13c2c2;
  --color-cyan-7: #08979c;
  --color-cyan-8: #006d75;
  --color-cyan-9: #00474f;
  --color-cyan-10: #002329;
  --color-green-1: #f6ffed;
  --color-green-2: #d9f7be;
  --color-green-3: #b7eb8f;
  --color-green-4: #95de64;
  --color-green-5: #73d13d;
  --color-green-6: #52c41a;
  --color-green-7: #389e0d;
  --color-green-8: #237804;
  --color-green-9: #135200;
  --color-green-10: #092b00;
  --color-lime-1: #fcffe6;
  --color-lime-2: #f4ffb8;
  --color-lime-3: #eaff8f;
  --color-lime-4: #d3f261;
  --color-lime-5: #bae637;
  --color-lime-6: #a0d911;
  --color-lime-7: #7cb305;
  --color-lime-8: #5b8c00;
  --color-lime-9: #3f6600;
  --color-lime-10: #254000;
  --color-yellow-1: #feffe6;
  --color-yellow-2: #ffffb8;
  --color-yellow-3: #fffb8f;
  --color-yellow-4: #fff566;
  --color-yellow-5: #ffec3d;
  --color-yellow-6: #fadb14;
  --color-yellow-7: #d4b106;
  --color-yellow-8: #ad8b00;
  --color-yellow-9: #876800;
  --color-yellow-10: #614700;
  --color-gold-1: #fffbe6;
  --color-gold-2: #fff1b8;
  --color-gold-3: #ffe58f;
  --color-gold-4: #ffd666;
  --color-gold-5: #ffc53d;
  --color-gold-6: #faad14;
  --color-gold-7: #d48806;
  --color-gold-8: #ad6800;
  --color-gold-9: #874d00;
  --color-gold-10: #613400;
  --color-orange-1: #fff7e6;
  --color-orange-2: #ffe7ba;
  --color-orange-3: #ffd591;
  --color-orange-4: #ffc069;
  --color-orange-5: #ffa940;
  --color-orange-6: #fa8c16;
  --color-orange-7: #d46b08;
  --color-orange-8: #ad4e00;
  --color-orange-9: #873800;
  --color-orange-10: #612500;
  --color-red-1: #fff1f0;
  --color-red-2: #ffccc7;
  --color-red-3: #ffa39e;
  --color-red-4: #ff7875;
  --color-red-5: #ff4d4f;
  --color-red-6: #f5222d;
  --color-red-7: #cf1322;
  --color-red-8: #a8071a;
  --color-red-9: #820014;
  --color-red-10: #5c0011;
  --color-volcano-1: #fff2e8;
  --color-volcano-2: #ffd8bf;
  --color-volcano-3: #ffbb96;
  --color-volcano-4: #ff9c6e;
  --color-volcano-5: #ff7a45;
  --color-volcano-6: #fa541c;
  --color-volcano-7: #d4380d;
  --color-volcano-8: #ad2102;
  --color-volcano-9: #871400;
  --color-volcano-10: #610b00;
  --color-gray-1: #ffffff;
  --color-gray-2: #fafafa;
  --color-gray-3: #f5f5f5;
  --color-gray-4: #f0f0f0;
  --color-gray-5: #d9d9d9;
  --color-gray-6: #bfbfbf;
  --color-gray-7: #8c8c8c;
  --color-gray-8: #595959;
  --color-gray-9: #434343;
  --color-gray-10: #262626;
  --color-gray-11: #1f1f1f;
  --color-gray-12: #141414;
  --color-gray-13: #000000;
  --color-gray-200: #edf2f7; /* deprecated */
  --color-gray-300: #e2e8f0; /* deprecated */
  --color-gray-600: #718096; /* deprecated */
  --color-gray-700: #4a5568; /* deprecated */
  --color-gray-900: #1a202c; /* deprecated */
  --color-charcoal-2: rgba(0, 0, 0, 0.02);
  --color-charcoal-4: rgba(0, 0, 0, 0.04);
  --color-charcoal-6: rgba(0, 0, 0, 0.06);
  --color-charcoal-15: rgba(0, 0, 0, 0.15);
  --color-charcoal-25: rgba(0, 0, 0, 0.25);
  --color-charcoal-45: rgba(0, 0, 0, 0.45);
  --color-charcoal-65: rgba(0, 0, 0, 0.65);
  --color-charcoal-85: rgba(0, 0, 0, 0.85);
  --color-chart-1: #85a5ff;
  --color-chart-2: #69c0ff;
  --color-chart-3: #5cdbd3;
  --color-chart-4: #95de64;
  --color-chart-5: #ffd666;
  --color-chart-6: #ffc069;
  --color-chart-7: #ff9c6e;
  --color-chart-8: #ff7875;
  --color-chart-9: #ff85c0;
  --color-chart-10: #b37feb;
  --color-black: #000000;
  --color-white: #ffffff;
  --space-1: 0.25rem;
  --space-2: 0.5rem;
  --space-3: 0.75rem;
  --space-4: 1rem;
  --space-5: 1.25rem;
  --space-6: 1.5rem;
  --space-8: 2rem;
  --space-10: 2.5rem;
  --space-12: 3rem;
  --space-14: 3.5rem;
  --space-16: 4rem;
  --space-20: 5rem;
  --space-24: 6rem;
  --space-32: 8rem;
  --space-0-5: 0.125rem;
  --typography-font-size-micro: 0.5625rem;
  --typography-font-size-xs: 0.75rem;
  --typography-font-size-sm: 0.875rem;
  --typography-font-size-base: 1rem;
  --typography-font-size-lg: 1.125rem;
  --typography-font-size-xl: 1.25rem;
  --typography-font-size-2xl: 1.5rem;
  --typography-font-size-3xl: 2rem;
  --typography-font-size-4xl: 2.5rem;
  --typography-line-height-3: .75rem;
  --typography-line-height-4: 1rem;
  --typography-line-height-5: 1.25rem;
  --typography-line-height-6: 1.5rem;
  --typography-line-height-7: 1.75rem;
  --typography-line-height-8: 2rem;
  --typography-line-height-9: 2.25rem;
  --typography-line-height-10: 2.5rem;
  --typography-line-height-11: 2.75rem;
  --typography-line-height-none: 1rem;
  --typography-line-height-tight: 1.25rem;
  --typography-line-height-snug: 1.375rem;
  --typography-line-height-normal: 1.5rem;
  --typography-line-height-relaxed: 1.625rem;
  --typography-line-height-loose: 2rem;
  --typography-font-weight-thin: 400;
  --typography-font-weight-normal: 400;
  --typography-font-weight-medium: 500;
  --typography-font-weight-bold: 600;
}
